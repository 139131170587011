import { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { bi } from '../bi';
import { EditorSDKUtils } from '../utils';
import {
  createGfppActions,
  presetListenerCallback,
  onAddWidget,
  onSitePublished,
  onRemoveWidget,
} from './utils';

export const editorAppEventListener = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options: EditorReadyOptions,
) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const { t } = flowAPI.translations;

  await editorSDKUtils.onAddWidgetListener(async (event) => {
    const currentRef = event.detail.compRef.id;
    const refs = await editorSDKUtils.getAllAppRefComponents();
    const refExists = refs.find((ref) => ref.id === currentRef);
    if (refExists) {
      onAddWidget(event, editorSDK, flowAPI);
    }
  });

  await editorSDKUtils.sitePublishedListener(async (event) => {
    onSitePublished({ editorSDK, flowAPI });
  });

  await editorSDKUtils.onDeleteWidgetListener(async (event) => {
    onRemoveWidget(event, editorSDK);
  });

  await editorSDKUtils.addWidgetGfppClickListener(async (event) => {
    const { id, componentRef } = event.detail;
    console.log('<<Event listener', { id, componentRef });
    const msid = await editorSDKUtils.getMetaSiteId();
    const instance_id = await editorSDKUtils.getInstanceId();
    const biLogger = bi({ flowAPI, msid, instance_id });
    const biBtnClick = biLogger.sitePromoButtonClick;

    const gfppActions = createGfppActions({
      editorSDK,
      componentRef,
      options,
      flowAPI,
    });

    switch (id) {
      case 'openDashboard':
        gfppActions.openBm();
        biBtnClick('manage_site_promos');
        break;
      case 'settingsPanel':
        gfppActions.openSettings();
        biBtnClick('widget_settings');
        break;
      case 'openElementsPanel':
        gfppActions.openElements({ t });
        biBtnClick('widget_elements');
        break;
      case 'openLayoutPanel':
        gfppActions.openLayout();
        biBtnClick('widget_layout');
        break;
      default:
        console.log('Could not find right gfpp', { id, componentRef });
    }
  });

  await editorSDKUtils.addGlobalDesignPresetChanged(async (event) => {
    await presetListenerCallback({ event, editorSDK, flowAPI });
  });
};
