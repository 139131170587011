import { Utils } from '@wix/promotions-types';
import { HttpClient, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorSDKUtils } from '../utils';

export const getUserConfig = async (editorSDK: FlowEditorSDK) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const instance = await editorSDKUtils.getInstance();
  const userApi = Utils.UserConfigApi(new HttpClient(), instance);
  const widgetData = await userApi.queryUserConfigRest();
  return { userApi, widgetData };
};
