import type { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { installWidget } from '../utils';
import { editorAppEventListener } from './editorAppEventListener';
import { getUserConfig } from './getUserConfig';
import { setCodeProvision } from './setCodeProvision';

export const onEditorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  console.log('=>>>', { options, flowAPI, editorSDK });
  const { userApi, widgetData } = await getUserConfig(editorSDK);
  const isAdded = widgetData?.addWidget.isAddWidget || false;

  if (options.firstInstall || isAdded) {
    installWidget({ editorSDK, flowAPI }).then(async () => {
      await userApi.updateUserConfigRest({
        _id: widgetData?._id,
        addWidget: { isAddWidget: false, widgetType: 'announcements_type' },
      });
      setCodeProvision(editorSDK);
    });
  }

  editorAppEventListener(editorSDK, flowAPI, options);
};
