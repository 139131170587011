import _ from 'lodash';
import { ICampaign, IDataItem } from '@wix/promotions-types';
import { emptyPromoSlot } from '../constants';
import { ClientApi } from '.';

interface SetStatusApiI {
  campaignId: string;
  prevCampaignId?: string;
  refId: string;
}

export const updateCampaignStatus = (instance: string) => {
  const setCampaignToActive = async ({
    campaignId,
    refId,
  }: SetStatusApiI): Promise<void> => {
    console.log('================ setToActive');
    if (!campaignId || !refId) {
      console.log('setCampaignToActive no cmp|ref id', { campaignId, refId });
      return;
    }

    const campaign = await ClientApi.getCampaign(campaignId);

    if (!campaign.id) {
      console.log('setCampaignToActive no campaign fetched', {
        campaign,
        campaignId,
      });
      return;
    }

    const campaignCopy = _.cloneDeep(campaign.data) as IDataItem;

    // campaignCopy.connectedWidgets[refId] = 'connected';

    console.log('setCampaignToActive Data After manipulation', {
      campaignCopy,
      refId,
    });

    ClientApi.updateCampaign({
      instance,
      campaignId,
      campaign: campaignCopy,
      context: 'updateCampaignStatus',
    });
  };

  const detachCampaign = async ({ campaign, refId }): Promise<void> => {
    const campaignId = campaign.id;
    console.log('================ setTo Empty STARTED');
    if (!campaignId || !refId) {
      console.log('detachCampaign campaignId|refId are missing ', {
        campaignId,
        refId,
      });
      return;
    }

    if (!campaign.id || !campaign.data) {
      console.log('detachCampaign No campaign found', { campaign });
      return;
    }
    const body: ICampaign = {
      ...campaign.data,
      promoSlot: emptyPromoSlot,
    } as ICampaign;

    ClientApi.updateCampaign({
      instance,
      campaignId,
      campaign: body,
      context: 'cleanCampaignSlot',
    });
  };

  const setCampaignToInactive = async ({
    campaignId,
    refId,
  }: SetStatusApiI): Promise<void> => {
    console.log('================ setTo InActive STARTED');
    if (!campaignId || !refId) {
      console.log('setCampaignToInactive campaignId|refId are missing ', {
        campaignId,
        refId,
      });
      return;
    }

    const campaign = await ClientApi.getCampaign(campaignId);

    if (!campaign.id || !campaign.data) {
      console.log('setCampaignToInactive No campaign found', { campaign });
      return;
    }

    const campaignCopy = _.cloneDeep(campaign.data) as IDataItem;

    const widgetStatus = campaignCopy.connectedWidgets[refId];

    if (!campaignCopy || !widgetStatus) {
      console.log('setCampaignToInactive No campain or ref in cmp array', {
        campaign,
        campaignCopy,
        widgetStatus,
      });
      return;
    }

    campaignCopy.connectedWidgets[refId] = 'deleted';
    console.log('delete stats', { campaign, campaignId, refId, widgetStatus });

    ClientApi.updateCampaign({
      instance,
      campaignId,
      campaign: campaignCopy,
      context: 'setCampaignToInactive',
    });
  };

  const setOnSwitchCampaign = async ({
    campaignId,
    prevCampaignId,
    refId,
  }): Promise<void> => {
    setCampaignToInactive({ campaignId: prevCampaignId, refId }).then(() =>
      setCampaignToActive({ campaignId, refId }).catch((e) =>
        console.error('Err onSwitch campaign', e),
      ),
    );
  };

  return {
    setCampaignToActive,
    setCampaignToInactive,
    setOnSwitchCampaign,
    detachCampaign,
  };
};
