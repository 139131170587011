import {
  CampaignType,
  EXPERIMENTS,
  ICampaignDataItem,
  Utils,
} from '@wix/promotions-types';
import {
  ComponentRef,
  PlatformControllerFlowAPI,
  type EditorScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { PropKeys } from '../enums';
import { EditorSDKUtils } from './editorSDKUtils';

interface SetInitialI {
  campaigns: ICampaignDataItem[];
  campaignType?: CampaignType;
  editorSDKUtils?: EditorSDKUtils;
  flowAPI: PlatformControllerFlowAPI;
  componentRef: ComponentRef;
}

export const setInitialCampaign = async ({
  campaigns,
  campaignType,
  editorSDKUtils,
  flowAPI,
  componentRef,
}: SetInitialI): Promise<string | void> => {
  campaignType = campaignType || 'announcements_type';
  const initialId = getInitialCampaign(
    componentRef,
    campaigns,
    flowAPI,
    campaignType,
  );
  if (!initialId) {
    return;
  }
  // Panels
  if (flowAPI) {
    const props = await flowAPI.controllerConfig.wixCodeApi.widget?.getProps();
    const campaignId = props?.campaignId;
    if (!campaignId) {
      flowAPI.controllerConfig.wixCodeApi.widget?.setProps({
        campaignId: initialId,
      });
    }
    // Editor
  } else if (editorSDKUtils) {
    const [allRef] = await editorSDKUtils.getAllAppRefComponents();
    const [componentRef] = await editorSDKUtils.getChildren(allRef);
    const { campaignId } = await editorSDKUtils.getWidgetProps(componentRef);
    if (campaignId) {
      return;
    }

    if (initialId) {
      editorSDKUtils.setWidgetProps({
        componentRef,
        newProps: { [PropKeys.campaignId]: initialId },
      });
    }
  }

  return initialId;
};

const filterCampaigns = (
  campaigns: ICampaignDataItem[],
  campaignType: CampaignType = 'announcements_type',
) => {
  const filterValidCampaigns = (campaign) =>
    campaign.data.campaignType === campaignType &&
    campaign.data.campaignStatus !== 'incomplete' &&
    Utils.isCampaignTimeValid(campaign);
  return campaigns.filter(filterValidCampaigns);
};

export const getLatestAvailableCampaign = (
  campaigns: ICampaignDataItem[],
  campaignType: CampaignType = 'announcements_type',
): ICampaignDataItem | undefined => {
  const validCampaigns = filterCampaigns(campaigns, campaignType);
  const filteredAndSortedCampaigns = validCampaigns
    .filter((campaign) => !campaign.data.promoSlot.id)
    .sort((a: ICampaignDataItem, b: ICampaignDataItem) => {
      const dateA = a.data._createdDate?.$date
        ? new Date(a.data._createdDate.$date).getTime()
        : 0;
      const dateB = b.data._createdDate?.$date
        ? new Date(b.data._createdDate.$date).getTime()
        : 0;
      return dateB - dateA;
    });

  return filteredAndSortedCampaigns[0];
};

export const getInitialCampaign = (
  componentRef: ComponentRef | undefined,
  campaigns: ICampaignDataItem[],
  flowAPI: PlatformControllerFlowAPI | EditorScriptFlowAPI,
  campaignType: CampaignType = 'announcements_type',
): string | undefined => {
  const validCampaigns = filterCampaigns(campaigns, campaignType);
  const totalCampaigns = validCampaigns.length;
  const singleCampaignId = totalCampaigns === 1 && validCampaigns[0]?.id;
  if (!totalCampaigns) {
    return;
  }

  // Connect a single campaign
  if (singleCampaignId) {
    return singleCampaignId;
  } else if (!flowAPI.experiments.enabled(EXPERIMENTS.MANAGE_PROMOS_BM)) {
    const lastAdded = validCampaigns.reduce((acc: any, campaign: any) => {
      const dateCreated = campaign.data._createdDate?.$date;
      const accDateCreated = acc.data._createdDate?.$date;
      return dateCreated > accDateCreated ? campaign : acc;
    });
    return lastAdded.id;
  } else {
    const campaign = validCampaigns.find(
      (cmp) =>
        cmp.data.promoSlot.id === componentRef?.id ||
        cmp.data.promoSlot.id === componentRef?.scope?.id,
    );
    if (campaign) {
      return campaign.id;
    }
    // // Connect the latest campaign
    // const lastAdded = validCampaigns.reduce((acc: any, campaign: any) => {
    //   const dateCreated = campaign.data._createdDate?.$date;
    //   const accDateCreated = acc.data._createdDate?.$date;
    //   return dateCreated > accDateCreated ? campaign : acc;
    // });
    // return lastAdded.id;
    return;
  }
};
