import { CampaignType, ICampaignDataItem } from '@wix/promotions-types';
import { TFunction, FlowEditorSDK, ComponentRef } from '@wix/yoshi-flow-editor';
import { getTypeByRef } from '../../editor-app/utils';
import { rolesFromType } from '../../enums';
import { ClientApi, EditorSDKUtils } from '../../utils';
import { getPresetsWithElementsToRemove } from './elementPanelHelpers';
import { CATEGORIES_DATA, PANEL_DATA } from './elementsPanelData';

export const createCategoriesData = (
  campaignType: CampaignType,
  t: TFunction
) =>
  CATEGORIES_DATA[campaignType].map((category) => ({
    ...category,
    title: t(category.title),
  }));

export const createElementsData = (campaignType: CampaignType, t: TFunction) =>
  PANEL_DATA[campaignType].map((el, index) => ({
    label: t(el.label),
    identifier: { role: el.role },
    index,
    multiState: el.multiState,
  }));

export const getElementsToRemove = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
): Promise<string[]> => {
  const elementsToRemove: string[] = [];
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const currentPreset = await editorSDKUtils.getPreset(widgetRef);
  const { campaignId } = await editorSDKUtils.getWidgetProps(widgetRef);
  const instance = await editorSDKUtils.getInstance();
  const campaignType = (await getTypeByRef({
    componentRef: widgetRef,
    editorSDK,
  })) as CampaignType;
  const elementRoles = rolesFromType[campaignType];

  const { presetsWithoutImage, presetsWithLine } =
    getPresetsWithElementsToRemove(campaignType);
  const isPresetWithoutImage = presetsWithoutImage.find((presetId) =>
    presetId.includes(currentPreset.layout as string)
  );

  const shouldHideLine = presetsWithLine.find(
    (presetId) => !presetId.includes(currentPreset.layout as string),
  );

  if (campaignId) {
    const campaign = (await ClientApi.getCampaign(
      campaignId,
      instance,
    )) as ICampaignDataItem;
    const { campaignHeading, campaignDisclaimer } = campaign.data.mainStateData;
    if (!campaignHeading) {
      elementsToRemove.push(elementRoles.Heading);
      // removeComponentHandler(widgetRef, { role: AnnouncementRoles.Heading });
    }
    if (isHTMLStringEmpty(campaignDisclaimer)) {
      elementsToRemove.push(elementRoles.Disclaimer);
      // removeComponentHandler(widgetRef, { role: AnnouncementRoles.Disclaimer });
    }
    if (isPresetWithoutImage) {
      elementsToRemove.push(elementRoles.Image);
    }
    if (shouldHideLine) {
      elementsToRemove.push(elementRoles.smallStripLine);
    }
  }

  return elementsToRemove;
};

const isHTMLStringEmpty = (htmlString: string) =>
  htmlString.replace(/<[^>]*>/g, '').trim() === '';
