// import { CampaignStatusType, ICampaign } from '@wix/promotions-types';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { sitePublishBi } from '../../../bi';
import {
  EditorSDKUtils,
  // statusSetter
} from '../../../utils';

type SitePublishedParams = {
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
};
export const onSitePublished = async ({
  editorSDK,
  flowAPI,
}: SitePublishedParams) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const msid = await editorSDKUtils.getMetaSiteId();
  const instance_id = await editorSDKUtils.getInstanceId();
  const instance = await editorSDKUtils.getInstance();

  await sitePublishBi({
    flowAPI,
    msid,
    instance_id,
    instance,
  });
};

// export const onSitePublished = async (editorSDK, isSaved = false) => {
//   const editorSDKUtils = new EditorSDKUtils(editorSDK);
//   const instance = await editorSDKUtils.getInstance();
// const campaigns = (await ClientApi.getCampaigns({
//   editorSDK,
// })) as ICampaignDataItem[];
//   campaigns.forEach((campaign) => {
//     let isChanged = false;
//     const deletedComps: string[] = [];
//     const campaignId = campaign.id;
//     const clonedCampaign = _.cloneDeep(campaign.data);
//     // If is saved go to another widget
//     let connectedWidgets = clonedCampaign.connectedWidgets;

//     for (const compId in connectedWidgets) {
//       if (connectedWidgets[compId] === 'connected') {
//         connectedWidgets[compId] = 'published';
//         isChanged = true;
//       } else if (connectedWidgets[compId] === 'deleted') {
//         deletedComps.push(compId);
//         isChanged = true;
//       }
//     }

//     ClientApi.dataCapsuleApi(editorSDK).then(({ getCapsuleItem }) => {
//       getCapsuleItem({ key: CAPSULE_KEYS.initialConnectedWidgets }).then(
//         (capsule) => {
//           console.log('Capsule published', { capsule });
//         }
//       );
//     });

//     if (isChanged) {
//       console.log('sitePublished changed');
//       if (deletedComps.length) {
//         connectedWidgets = _.omit(connectedWidgets, deletedComps);
//         clonedCampaign.connectedWidgets = connectedWidgets;
//         console.log('After Deletion', clonedCampaign);
//       }

//       const newStatus = getCampaignStatus(clonedCampaign);
//       clonedCampaign.campaignStatus = newStatus;

//       console.log('Updating campaign', { campaignId, clonedCampaign });
//       ClientApi.updateCampaign({
//         instance,
//         campaignId,
//         campaign: clonedCampaign,
//         context: 'onPublish',
//       });
//     }
//   });
// };

// const getCampaignStatus = (campaign: ICampaign): CampaignStatusType => {
//   const { connectedWidgets } = campaign;
//   const isConnectedWidgets = Object.keys(connectedWidgets);
//   let isCompPublished = false;
//   // Check overall status
//   if (isConnectedWidgets) {
//     for (const compId in connectedWidgets) {
//       if (connectedWidgets[compId] === 'published') {
//         isCompPublished = true;
//       }
//     }
//   }

//   if (isCompPublished) {
//     return statusSetter(campaign, 'active');
//   } else {
//     return statusSetter(campaign, 'draft');
//   }
// };
