import type { AppManifest, EditorReadyOptions } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { connectPromoPanelBi } from './bi';
import { modifyAppManifest, onEditorReady } from './editor-app';
import { EditorSDKUtils } from './utils';

let flowApi;

export function editorReady(
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> {
  flowApi = flowAPI;
  return onEditorReady(editorSDK, appDefId, options, flowAPI);
}

export const getAppManifest = async (options: any): Promise<AppManifest> => {
  return modifyAppManifest(options);
};

export const exports = (
  editorSDK: FlowEditorSDK,
  context: EditorReadyOptions,
) => ({
  private: {
    biReportConnectPromo: ({ msid, instance_id, instance, campaignId, compId }) => {
      connectPromoPanelBi({
        flowAPI: flowApi,
        msid,
        instance_id,
        instance,
        campaignId,
        compId,
      });
    },
  },
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      const editorSdkUtils = new EditorSDKUtils(editorSDK);
      const preset = await editorSdkUtils.getPreset(widgetRef);
      return preset.style;
    },
  },
});
