import { CampaignType } from '@wix/promotions-types';

enum AnnouncementsPanels {
  settingsEmptystate = 'Settings empty state',
  settingsEmptystateExp = 'Settings empty state expired',
  settingsChooseCampaign = 'Settings choose campaign',
  layout = 'Layout',
}

enum LimitedTimePanels {
  settingsEmptystate = 'Settings empty state',
  settingsEmptystateExp = 'Settings empty state expired',
  settingsChooseCampaign = 'Settings choose campaign',
  layout = 'Layout',
}

enum SubscribePanels {
  settingsEmptystate = 'Settings empty state 4',
  settingsEmptystateExp = 'Settings empty state expired 4',
  settingsChooseCampaign = 'Settings choose campaign 4',
  layout = 'Layout 4',
}

export const PanelsByType: Record<CampaignType, any> = {
  announcements_type: AnnouncementsPanels,
  get_subscribers_type: SubscribePanels,
  limited_offer_type: LimitedTimePanels,
};
