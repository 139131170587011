import _ from 'lodash';
import {
  LocalStorageCachedCapsule,
  WixStorageStrategy,
} from '@wix/data-capsule';
import { HttpClient } from '@wix/yoshi-flow-editor';
import { CAPSULE_KEYS, CAPSULE_NAMESPACE } from '../../enums';

type CapsuleKey = CAPSULE_KEYS | string;

interface IGetDelData {
  key: CapsuleKey;
}
interface ISetData extends IGetDelData {
  data: any;
}

const METASITE_ID = '1ada7622-489c-4f31-be6c-87c9b04ed5e4';

type DataCapsuleParams = {
  instance: string;
  msid: string;
};
export const dataCapsuleApi = async ({ instance, msid }: DataCapsuleParams) => {
  const httpClient = new HttpClient({
    headers: { authorization: instance },
  });

  const capsule = LocalStorageCachedCapsule({
    remoteStrategy: new WixStorageStrategy({ httpClient }),
    scope: msid || METASITE_ID,
    namespace: CAPSULE_NAMESPACE.promotionsEditor,
  });

  const setCapsuleItem = async ({ key, data }: ISetData) => {
    try {
      const prevData = await getCapsuleItem({ key });
      const mergedData = _.merge({}, prevData, data);
      await capsule.setItem(key, mergedData);
      const res = await getCapsuleItem({ key });
      return res;
    } catch (error) {
      console.log((error as Error).message);
    }
  };

  async function getCapsuleItem({ key }: IGetDelData) {
    try {
      const data = await capsule.getItem(key);
      return data;
    } catch (error) {
      console.log((error as Error).message);
      return {};
    }
  }

  async function removeItem({ key }: IGetDelData): Promise<void> {
    try {
      await capsule.removeItem(key);
    } catch (error) {
      console.log((error as Error).message);
    }
  }

  async function getAllItems() {
    try {
      const allItems = await capsule.getAllItems();
      return allItems;
    } catch (error) {
      console.log((error as Error).message);
    }
  }

  return {
    setCapsuleItem,
    getCapsuleItem,
    removeItem,
    getAllItems,
  };
};
