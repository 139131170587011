import {
  FlowEditorSDK,
  type EditorScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { AnnouncementRoles, PropKeys } from '../../../enums';
import {
  EditorSDKUtils,
  getInitialCampaign,
  // setInitialCampaign,
  // updateCampaignStatus,
} from '../../../utils';
import { fetchAndUpdateCampaigns } from '../../../viewer-utils';

export const onAddWidget = async (
  event,
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
) => {
  console.log('On add widget');
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const instance = await editorSDKUtils.getInstance();
  const { compRef } = event.detail;

  await editorSDKUtils.removeElementFromPanel({
    componentRef: compRef,
    role: AnnouncementRoles.Disclaimer,
  });

  const [componentRef] = await editorSDKUtils.getChildren(compRef);

  await editorSDKUtils.setWidgetProps({
    componentRef,
    newProps: { [PropKeys.compId]: compRef.id },
  });

  await fetchAndUpdateCampaigns(instance).then(async (campaigns) => {
    const initialId = getInitialCampaign(componentRef, campaigns, flowAPI);
    if (initialId) {
      await editorSDKUtils.setWidgetProps({
        componentRef,
        newProps: { [PropKeys.campaignId]: initialId },
      });
    }
  });

  // const { compId } = await editorSDKUtils.getWidgetProps(componentRef);

  // await updateCampaignStatus(instance).setCampaignToActive({
  //   campaignId,
  //   refId: compId,
  // });
};
