import { ICampaignDataItem, Utils } from '@wix/promotions-types';
import { FlowEditorSDK, HttpClient } from '@wix/yoshi-flow-editor';
import { EditorSDKUtils, updateCampaignStatus } from '../../../utils';

export const onRemoveWidget = async (event, editorSDK: FlowEditorSDK) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const instance = await editorSDKUtils.getInstance();
  const httpClient = new HttpClient();
  const compRef = event?.detail?.compRef || event?.detail?.componentRef || '';
  const refId = compRef?.id;

  const campaigns = (await Utils.DataItemApi(
    instance,
    httpClient,
  ).queryDataItems({ shouldUpdate: false })) as ICampaignDataItem[];

  const campaign = campaigns.find(
    (campaign) => campaign.data.promoSlot.id === refId,
  );
  const campaignId = campaign?.id;

  if (!compRef || !refId || !campaignId) {
    console.log('onRemoveWidget no comp ref', {
      event,
      compRef,
      campaignId,
      refId,
    });
    return;
  }

  await updateCampaignStatus(instance).detachCampaign({
    campaign,
    refId,
  });
};
