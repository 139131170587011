import { EXPERIMENTS } from '@wix/promotions-types';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorUtils } from '../editor-app';
import { WIDGETS } from '../enums/widget';
import { connectToLatestCampaign } from './connectToLatestCampaign';
import { EditorSDKUtils } from './editorSDKUtils';

export const installWidget = async ({
  editorSDK,
  flowAPI,
}: {
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  await editorSDKUtils.reloadManifest();
  const { isEditorX } = flowAPI.environment;
  const widgetIdToInstall = WIDGETS.ANNOUNCEMENT;

  const componentRef = await editorSDKUtils.addWidget(
    EditorUtils.getMainWidgetOptions(widgetIdToInstall, null),
  );

  console.log('Component Ref', componentRef);

  if (!isEditorX) {
    await editorSDKUtils.setFullWidth(componentRef);
  }
  const isManagePromoBM = flowAPI.experiments.enabled(
    EXPERIMENTS.MANAGE_PROMOS_BM,
  );
  isManagePromoBM && connectToLatestCampaign({ editorSDK, componentRef });
  return { componentRef };
};
