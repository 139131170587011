import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { presetsPanelBi } from '../../bi';
import { AnnouncementRoles } from '../../enums';
import { EditorSDKUtils, presets } from '../../utils';

type PresetListenerParams = {
  event: any;
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
};

export async function presetListenerCallback({
  event,
  editorSDK,
  flowAPI,
}: PresetListenerParams) {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const msid = await editorSDKUtils.getMetaSiteId();
  const instance_id = await editorSDKUtils.getInstanceId();
  const { preset, componentRef } = event.detail;
  const instance = await editorSDKUtils.getInstance();
  const compId = componentRef.id.split('_')[0];

  await presetsPanelBi({
    flowAPI,
    msid,
    instance_id,
    instance,
    preset,
    compId,
  });

  const getConnectedMobilePresetId = (preset: string) => {
    const selectedPreset = presets().ANNOUNCEMENTS.find((p) => p.id === preset);
    return selectedPreset?.connectedMobilePresetID;
  };

  const [parentRefComponent] = await editorSDKUtils.getAncestors(componentRef);
  const props = await editorSDKUtils.getWidgetProps(componentRef);
  const newProps = JSON.parse(JSON.stringify(props));
  if (preset && parentRefComponent) {
    const [imageRef] = await editorSDKUtils.findAllByRole(
      componentRef,
      AnnouncementRoles.Image,
    );

    const [imgStructure] = await editorSDKUtils.getCompStructure(imageRef);

    await editorSDKUtils.removeAllOverrides(parentRefComponent);
    await editorSDKUtils.changePreset(parentRefComponent, 'DESKTOP', preset);
    const connectedMobilePresetId = getConnectedMobilePresetId(preset);
    if (connectedMobilePresetId) {
      await editorSDKUtils.changePreset(
        parentRefComponent,
        'MOBILE',
        connectedMobilePresetId,
      );
    }
    // Keep Same image on preset change
    await editorSDKUtils.updateComponent(imageRef, imgStructure.data);
    // await editorSDKUtils.refreshLivePreview(true, '');
  }
  await editorSDKUtils.setWidgetProps({ componentRef, newProps });
  return componentRef;
}
