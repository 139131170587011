import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { SITE_PROMOS_HELP_ARTICLE_ID } from '../../constants';
import { manipluateGFPP } from '../../editor-app/utils';
import { AnnouncementRoles } from '../../enums';
import { WidgetDesignBuilder } from '../../types';
import { EditorSDKUtils, presets } from '../../utils';
import globalDesignBuilder from '../../editor-app/globalDesignBuilder';

const textElArr = [
  AnnouncementRoles.Heading,
  AnnouncementRoles.Title,
  AnnouncementRoles.Subtitle,
  AnnouncementRoles.Disclaimer,
];

const btnElements = [
  AnnouncementRoles.LinkBtn,
  AnnouncementRoles.CouponCodeBtn,
];

const buttonElements = [
  { id: AnnouncementRoles.LinkBtn },
  { id: AnnouncementRoles.CouponCodeBtn, isLabelHidden: true },
];
const imageElements = [AnnouncementRoles.Image];

export const getWidgetManifest: GetWidgetManifestFn = async (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  const { removeGfppBtn, openBmOnMainAction2, updateLinkAndTextGfpp } =
    manipluateGFPP(widgetBuilder, editorSDK, t);
  const { isClassicEditor } = flowAPI.environment;
  widgetBuilder.set({
    displayName: t('promotions.widget.announcement.label'),
  });

  widgetBuilder
    .gfpp()
    .set('connect', { behavior: 'HIDE' })
    .set('help', {
      id: SITE_PROMOS_HELP_ARTICLE_ID,
    })
    .set('mainAction1', {
      label: t('promotions.widget.announcement.gfpp.mainAction1'),
      actionId: 'openDashboard',
    })
    .set('mainAction2', {
      label: t('promotions.widget.announcement.gfpp.mainAction2'),
      actionId: 'settingsPanel',
    });

  // Remove edit text btn from mobile text elements
  removeGfppBtn({
    elements: textElArr,
    btnToRemove: 'mainAction2',
    isMobile: true,
  });

  // Not working
  removeGfppBtn({
    elements: btnElements,
    btnToRemove: 'mainAction1',
    isMobile: true,
  });
  removeGfppBtn({
    elements: btnElements,
    btnToRemove: 'mainAction2',
    isMobile: true,
  });
  removeGfppBtn({
    elements: btnElements,
    btnToRemove: 'layout',
    isMobile: true,
  });

  removeGfppBtn({
    elements: imageElements,
    btnToRemove: 'link',
  });

  widgetBuilder.configureWidgetDesign(
    (widgetDesignBuilder: WidgetDesignBuilder) => {
      widgetDesignBuilder.set({
        title: t('promotions.panel.presets.title'),
        presetsTitle: t('promotions.panel.presets.presetsTitle'),
      });
      widgetDesignBuilder.setPresets(presets(isClassicEditor).ANNOUNCEMENTS as any);
      globalDesignBuilder(widgetDesignBuilder, t);
    },
  );

  openBmOnMainAction2({ elements: textElArr, flowAPI });
  updateLinkAndTextGfpp({ elements: buttonElements });

  widgetBuilder.configureConnectedComponents(
    AnnouncementRoles.LinkBtn,
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .gfpp('mobile')
        .set('mainAction2', {
          behavior: 'HIDE',
        })
        .set('mainAction1', {
          behavior: 'HIDE',
        })
        .set('layout', {
          behavior: 'HIDE',
        });
    }
  );
};

export const exports = (editorSDK: FlowEditorSDK) => ({
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      const editorSdkUtils = new EditorSDKUtils(editorSDK);
      const preset = await editorSdkUtils.getPreset(widgetRef);
      return preset.style;
    },
  },
});
