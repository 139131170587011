import {
  ComponentRef,
  EditorSDK,
  RemoveComponentHandler,
} from '@wix/platform-editor-sdk';
import { CampaignType } from '@wix/promotions-types';
import { EditorScriptFlowAPI, TFunction } from '@wix/yoshi-flow-editor';
import { elementsPanelBi } from '../../bi';
import { SubscribeRoles } from '../../enums';
import { EditorSDKUtils } from '../../utils';
import { createElementsData, getElementsToRemove } from './elementsPanelUtils';

export const openElementsPanel = async (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  campaignType: CampaignType,
  t: TFunction,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  const compId = widgetRef.id.split('_')[0];
  const editorSDKUtils = new EditorSDKUtils(editorSDK);

  let selectedStateId;
  if (campaignType === 'get_subscribers_type') {
    const multistateBox = await editorSDKUtils.findAllByRole(
      widgetRef,
      SubscribeRoles.CtaMultiStateBox
    );
    const multiStateData = await editorSDKUtils.getComponentData(multistateBox);
    // @ts-expect-error
    selectedStateId = multiStateData.selectedStateId;
  }

  const getCollapsedRefComponentByRole = async (role: string) => {
    const [widgetRefHost] = await editorSDKUtils.getAncestors(widgetRef);
    const collapsedRefComponents =
      await editorSDKUtils.getCollapsedRefComponents(widgetRefHost, true);
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );
    return collapsedRefComponent[0]?.componentRef;
  };

  const getCompToHide = async (componentRef) => {
    const type = await editorSDKUtils.getType(componentRef);
    return type.includes('AppWidget')
      ? (await editorSDKUtils.getAncestors(componentRef))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const showComponent = async (componentRef) => {
    await editorSDKUtils.expandReferredComponent(componentRef);
    // return editorSDKUtils.refreshLivePreview(false, 'AFTER_DB_CHANGE');
  };

  const hideComponent = async (componentRef) => {
    await editorSDKUtils.collapseReferredComponent(componentRef);
  };

  const getInstanceAndMsid = async () => {
    const msid = await editorSDKUtils.getMetaSiteId();
    const instance_id = await editorSDKUtils.getInstanceId();
    const instance = await editorSDKUtils.getInstance();
    return { msid, instance_id, instance };
  };

  const addComponentHandler = async (
    { role }: { role: string },
    compRef: ComponentRef | undefined,
  ): Promise<void> => {
    const componentRef =
      compRef || (await getCollapsedRefComponentByRole(role));
    const { msid, instance_id, instance } = await getInstanceAndMsid();

    return showComponent(componentRef).then(() => {
      elementsPanelBi({
        flowAPI,
        msid,
        instance_id,
        instance,
        role,
        compId,
        isOn: true,
      });
    });
  };

  const removeComponentHandler: RemoveComponentHandler = async (
    compRef,
    { role },
  ): Promise<void> => {
    const compToHide = await getCompToHide(compRef);
    const { msid, instance_id, instance } = await getInstanceAndMsid();
    return hideComponent(compToHide).then(() => {
      elementsPanelBi({
        flowAPI,
        msid,
        instance_id,
        instance,
        role,
        compId,
        isOn: false,
      });
    });
  };

  const elementsToRemove = await getElementsToRemove(editorSDK, widgetRef);

  const filterElements = (element) =>
    !elementsToRemove.includes(element.identifier.role);

  const filterElementsByState = (element) => {
    return selectedStateId ? element.multiState === selectedStateId : true;
  };

  const elementsData = createElementsData(campaignType, t)
    .filter(filterElements)
    .filter(filterElementsByState);

  return editorSDKUtils.openElementsPanel({
    widgetRef,
    elementsData,
    addComponentHandler,
    removeComponentHandler,
  });
};
