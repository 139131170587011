import { Utils } from '@wix/promotions-types';
import { HttpClient, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorSDKUtils } from '../utils';

export const setCodeProvision = async (editorSDK: FlowEditorSDK) => {
  const httpClient = new HttpClient();
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const instance = await editorSDKUtils.getInstance();
  await Utils.WixCodeApi(httpClient, instance).provisionApp();
};
