import _ from 'lodash';
import { ClientApi } from '../utils';
import { ElementsPanelBtns, PromoPublishedParams } from './biTypes';
import { BiBaseParams, bi } from './biUtils';

interface PanelsBi extends BiBaseParams {
  instance: string;
  compId: string;
}
interface ElementsPanelBi extends PanelsBi {
  role: string;
  isOn: boolean;
}

interface PresetsPanelBi extends PanelsBi {
  preset: string;
}

interface ConnectPromoPanelBi extends PanelsBi {
  campaignId: string;
}

interface SitePublishedBi extends Omit<PanelsBi, 'compId'> {}

export const elementsPanelBi = async ({
  flowAPI,
  msid,
  instance_id,
  instance,
  role,
  compId,
  isOn = true,
}: ElementsPanelBi) => {
  const biLogger = bi({ flowAPI, msid, instance_id });
  biLogger.sitePromoButtonClick(
    `${role}${isOn ? '_on' : '_off'}` as ElementsPanelBtns,
  );
  const dataCapsule = await ClientApi.dataCapsuleApi({ instance, msid });

  const data = await dataCapsule.setCapsuleItem({
    key: compId,
    data: { elements: { [role]: isOn } }, // TODO add elements obj
  });

  console.log('>>>>>>>>>>> elementsPanelBi = ', data);
};

export const presetsPanelBi = async ({
  flowAPI,
  msid,
  instance_id,
  instance,
  preset,
  compId,
}: PresetsPanelBi) => {
  const biLogger = bi({ flowAPI, msid, instance_id });

  biLogger.sitePromoButtonClick('select_preset', {
    entity: 'preset',
    entity_id: preset,
  });

  const dataCapsule = await ClientApi.dataCapsuleApi({ instance, msid });
  const data = await dataCapsule.setCapsuleItem({
    key: compId,
    data: { preset },
  });
  console.log('>>>>>>>>>>> presetsPanelBi = ', data);
};

export const connectPromoPanelBi = async ({
  flowAPI,
  msid,
  instance_id,
  instance,
  campaignId,
  compId,
}: ConnectPromoPanelBi) => {
  const biLogger = bi({ flowAPI, msid, instance_id });
  biLogger.sitePromoButtonClick('select_promo_done');

  if (compId) {
    const dataCapsule = await ClientApi.dataCapsuleApi({
      msid: msid || '',
      instance,
    });
    const data = await dataCapsule.setCapsuleItem({
      key: compId,
      data: { campaignId },
    });
    console.log('>>>>>>>>>>> connectPromoPanelBi = ', data);
  }
};

type BiCapsuleData = {
  campaignId?: string;
  preset?: string;
  elements?: { [key: string]: boolean };
};

type BiCapsuleObj = { [key: string]: BiCapsuleData };

export const sitePublishBi = async ({
  flowAPI,
  msid,
  instance_id,
  instance,
}: SitePublishedBi) => {
  const dataCapsule = await ClientApi.dataCapsuleApi({ msid, instance });
  const capsuleData = await dataCapsule.getAllItems();
  const biLogger = bi({ flowAPI, msid, instance_id });
  console.log('>>>>>>>>>>> capsuleData = ', capsuleData);

  processData(capsuleData);

  async function processData(data: BiCapsuleObj) {
    for (const [itemKey, itemValue] of Object.entries(data)) {
      if (!_.isEmpty(itemValue)) {
        const biData: PromoPublishedParams = {
          promo_id: itemValue?.campaignId || '',
          preset_id: itemValue?.preset || null,
          widget_elements: itemValue?.elements
            ? JSON.stringify(itemValue.elements)
            : '',
        };

        console.log('>>>>>>>>>>> biData = ', biData);
        biLogger.sitePromoPublished(biData);
      }
      await dataCapsule.removeItem({ key: itemKey });
    }
  }
};
