import { ICampaign } from '@wix/promotions-types';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { PropKeys } from '../enums';
import { ClientApi, EditorSDKUtils, getLatestAvailableCampaign } from '.';

export const connectToLatestCampaign = async ({
  editorSDK,
  componentRef,
}: {
  editorSDK: FlowEditorSDK;
  componentRef: ComponentRef;
}) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);

  const campaigns = await ClientApi.getCampaigns({ editorSDK });

  const availableCampaign = getLatestAvailableCampaign(campaigns);

  const childrenComponentRef = (
    await editorSDKUtils?.getChildren(componentRef)
  )?.at(0);

  const campaignType = 'announcements_type';

  const instance = await editorSDKUtils?.getInstance();

  const body: ICampaign = {
    ...availableCampaign?.data,
    promoSlot: {
      id: componentRef?.id,
      widgetName: `${campaignType}_${componentRef?.id}`,
      slotType: campaignType,
      component: componentRef,
      pagePath: '/',
    },
  } as ICampaign;
  instance &&
    availableCampaign &&
    (await ClientApi.updateCampaign({
      instance,
      campaignId: availableCampaign.id,
      campaign: body,
      context: 'updateCampaignSlot',
    }));
  availableCampaign &&
    editorSDKUtils?.setWidgetProps({
      componentRef: childrenComponentRef,
      newProps: { [PropKeys.campaignId]: availableCampaign.id },
    });
};
