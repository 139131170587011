import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { AppManifest } from '@wix/platform-editor-sdk';

export const modifyAppManifest = async (options: any): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);

  const manifest = appManifestBuilder.withJsonManifest(baseManifest).build();

  // Hide elements panel on mobile
  const controllerId = '7fdf5576-9d52-4c0a-9840-6e226f2ec415-t0i9y';
  manifest.controllersStageData[
    controllerId
  ].default.gfpp.mobile.iconButtons.add = { behavior: 'HIDE' };

  return manifest;
};
