import { IPromoSlot } from '@wix/promotions-types';

export const EDITOR_HOOKS = {
  settingPanel: 'settings.wrapper',
};

export const DIMENSIONS = {
  panelHeight: 290,
  panelWidth: 288,
};
// TODO replace it!!!!
export const HELP_ID = '985166aa-e528-474b-aecf-4b44017c377a';

export const emptyPromoSlot: IPromoSlot = {
  id: '',
  widgetName: '',
  pagePath: '',
  slotType: 'announcements_type',
  component: {},
};
