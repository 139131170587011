import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
// import { EditorUtils } from '../../editor-app';
import { WidgetDesignBuilder } from '../../types';
import { presets } from '../../utils';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  const { isClassicEditor } = flowAPI.environment;

  widgetBuilder.set({
    displayName: 'Widget Subscribe',
    nickname: 'SubscribeWidget',
  });

  // EditorUtils.gfppUtils.setDesktopGFPP(widgetBuilder);

  widgetBuilder.configureWidgetDesign(
    (widgetDesignBuilder: WidgetDesignBuilder) => {
      widgetDesignBuilder.set({
        title: t('promotions.panel.presets.title'),
        presetsTitle: t('promotions.panel.presets.presetsTitle'),
      });
      widgetDesignBuilder.setPresets(presets(isClassicEditor).SUBSCRIBE as any);
    },
  );
};
