import type { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { Widget } from '../../types';

export const getMainWidgetOptions = (widget: Widget, pageRef: any) => {
  return {
    // containerRef: pageRef as any,
    widgetId: widget.widgetId,
    installationType: 'closed' as WidgetInstallationType,
    scopedPresets: {
      desktop: {
        layout: widget.desktop,
        style: widget.desktop,
      },
      mobile: {
        layout: widget.mobile,
        style: widget.mobile,
      },
    },
    layout: {
      height: 568,
      width: 758,
      x: 0 + 20,
      y: 100 + 20,
      // Docked for stretching the widget
      docked: {
        left: {
          px: 0,
          vw: 0,
        },
        right: {
          px: 0,
          vw: 0,
        },
      },
      // docked: {
      //   hCenter: { px: 0 },
      //   vCenter: { px: 0 },
      // },
    },
    // For editor X
    layouts: {
      componentLayout: {
        minHeight: {
          type: 'px',
          value: 50,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'auto',
        },
      },
      itemLayout: {
        id: '',
        type: 'GridItemLayout',
        gridArea: {
          columnStart: 1,
          columnEnd: 2,
          rowStart: 1,
          rowEnd: 2,
        },
        alignSelf: 'stretch',
        justifySelf: 'stretch',
        margins: {
          left: {
            type: 'px',
            value: 0,
          },
          right: {
            type: 'px',
            value: 0,
          },
          top: {
            type: 'px',
            value: 0,
          },
          bottom: {
            type: 'px',
            value: 0,
          },
        },
      },
    },
  };
};
