import {
  CampaignType,
  ICampaignDataItem,
  Utils,
  Bi,
  Enums,
} from '@wix/promotions-types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { bi, connectPromoPanelBi } from '../../bi';
import { PropKeys, AnnouncementRoles as Roles } from '../../enums';
import { ClientApi, getInitialCampaign } from '../../utils';

interface CampaignOptionI {
  value: string;
  label: string;
}

const ccPrefix = 'promotions.panel.settings';

// const campaignTranslation: Record<CampaignType, string> = {
//   announcements_type: 'Announcement',
//   get_subscribers_type: 'Subscribe',
//   limited_offer_type: 'Limited time offer',
// };

interface ChooseCamapignPanel {
  $w: any;
  flowAPI: PlatformControllerFlowAPI;
  campaignType: CampaignType;
}

const getDataFromInstance = (instance: string = '') => ({
  msid:
    Bi.getDataFromInstance(instance, Enums.INSTANCE_KEYS.META_SITE_ID) || '',
  instance_id:
    Bi.getDataFromInstance(instance, Enums.INSTANCE_KEYS.INSTANCE_ID) || '',
});

export const chooseCamapignPanel = ({
  $w,
  flowAPI,
  campaignType,
}: ChooseCamapignPanel) => {
  const { t } = flowAPI.translations;
  $w.onReady(async () => {
    const wixWidget = flowAPI.controllerConfig.wixCodeApi.widget;
    const wixEditor = flowAPI.controllerConfig.wixCodeApi.editor;
    const instance = (await wixEditor?.getAppInstance()) || '';
    const props = await wixWidget?.getProps();
    const compId = props?.compId;
    const { msid, instance_id } = getDataFromInstance(instance);
    const biLogger = bi({ flowAPI, msid, instance_id });

    const getValidCampaigns = (cmp: ICampaignDataItem) =>
      cmp.data.campaignType === campaignType &&
      !Utils.getIsExpired(cmp.data.campaignDateEnd) &&
      cmp.data.campaignStatus !== 'incomplete';

    const campaignData = await ClientApi.getCampaigns({ instance });
    const campaignOptions = campaignData
      .filter(getValidCampaigns)
      .map((campaign) => ({
        value: campaign.id,
        label: campaign.data.campaignName,
      })) as CampaignOptionI[];

    $w(`#${Roles.CampaignPanelDD}`).placeholder = t(
      `${ccPrefix}.chooseCampaign.dropdown.placeholder`,
    );
    $w(`#${Roles.CampaignPanelDD}`).label = t(
      `${ccPrefix}.chooseCampaign.dropdown.title`,
    );
    $w(`#${Roles.CampaignPanelDD}`).options = campaignOptions;
    if (props?.campaignId) {
      const campaignId = props.campaignId;
      $w(`#${Roles.CampaignPanelDD}`).value = campaignId;

      await connectPromoPanelBi({
        flowAPI,
        msid,
        instance_id,
        instance,
        campaignId,
        compId: compId || '',
      });
    }

    $w(`#${Roles.CampaignPanelDD}`).expand();

    // NOT WORKING
    // $w(`#${Roles.CampaignPanelDD}`).onClick(() =>
    //   biLogger.sitePromoButtonClick('select_promo')
    // );

    $w(`#${Roles.CampaignPanelDD}`).onChange(async (e) => {
      const campaignId = e.target.value;
      await wixWidget?.setProps({ campaignId });

      await connectPromoPanelBi({
        flowAPI,
        msid,
        instance_id,
        instance,
        campaignId,
        compId: compId || '',
      });
    });
  });
};

interface EmptyStatePanel extends ChooseCamapignPanel {
  isExpired?: boolean;
}

export const emptyStatePanel = async ({
  $w,
  flowAPI,
  campaignType,
  isExpired,
}: EmptyStatePanel) => {
  const { t } = flowAPI.translations;
  const ePrefix = `promotions.panel.settings.emptyState${
    isExpired ? '.exp' : ''
  }`;
  const wixWidget = flowAPI.controllerConfig.wixCodeApi.widget;
  const wixEditor = flowAPI.controllerConfig.wixCodeApi.editor;
  const instance = (await wixEditor?.getAppInstance()) || '';
  const campaigns = await ClientApi.getCampaigns({ instance });
  const { msid, instance_id } = getDataFromInstance(instance);
  const biLogger = bi({ flowAPI, msid, instance_id });

  const count = campaigns.length;
  // const campaign = campaignTranslation[campaignType]; // For dyn translations

  $w(`#${Roles.EmptyStatePanelText}`).label = t(`${ePrefix}.label`, { count });
  $w(`#${Roles.EmptyStatePanelText}`).description = t(
    `${ePrefix}.description`,
    { count },
  );
  $w(`#${Roles.EmptyStatePanelBtn}`).buttonLabel = t(`${ePrefix}.buttonLabel`);

  $w(`#${Roles.EmptyStatePanelBtn}`).onClick(() => {
    wixEditor
      ?.openDashboardPanel({
        url: `/site-promos/campaigns/create?${campaignType}=true`,
      })
      .then(() => setInitialCamapign(wixWidget, instance, flowAPI));
    biLogger.sitePromoButtonClick('create_promo');
  });

  $w(`#${Roles.EmptyStatePanelText}`).expand();
  $w(`#${Roles.EmptyStatePanelBtn}`).expand();

  if (isExpired) {
    $w(`#${Roles.EmptyStateExpPanelBtn2}`).buttonLabel = t(
      `${ePrefix}.buttonLabel2`,
    );
    $w(`#${Roles.EmptyStateExpPanelBtn2}`).onClick(() => {
      wixEditor
        ?.openDashboardPanel({
          url: `/site-promos/campaigns?${campaignType}=true`,
        })
        .then(() => setInitialCamapign(wixWidget, instance, flowAPI));
      biLogger.sitePromoButtonClick('manage_site_promos');
    });
    $w(`#${Roles.EmptyStateExpPanelBtn2}`).expand();
  }
};

const setInitialCamapign = async (wixWidget, instance, flowAPI) => {
  const campaigns = await ClientApi.getCampaigns({ instance });
  const props = await wixWidget?.getProps();
  const initialCampaign = getInitialCampaign(undefined, campaigns, flowAPI);
  if (!props?.[PropKeys.campaignId] && initialCampaign) {
    await wixWidget?.setProps({
      [PropKeys.campaignId]: initialCampaign,
    });
  }
  // if (props?.[PropKeys.campaignId] && !initialCampaign) {
  //   await wixWidget?.setProps({
  //     [PropKeys.campaignId]: '',
  //   });
  // }
};
