import {
  IPanelsApi,
  PanelsApiFactory,
} from '@wix/blocks-widget-services/panels';
import { ComponentRef, EditorReadyOptions } from '@wix/platform-editor-sdk';
import {
  CampaignType,
  EXPERIMENTS,
  ICampaignDataItem,
  Utils,
} from '@wix/promotions-types';
import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/build/cjs/exports/utils';
import { DIMENSIONS, HELP_ID, presetToType } from '../../constants';
import { PanelsByType, PropKeys } from '../../enums';
import { openElementsPanel } from '../../panels';
import { ClientApi, EditorSDKUtils, getInitialCampaign } from '../../utils';

export const createGfppActions = ({
  editorSDK,
  componentRef,
  options,
  flowAPI,
}: {
  editorSDK: FlowEditorSDK;
  componentRef: ComponentRef;
  options?: EditorReadyOptions;
  flowAPI: EditorScriptFlowAPI;
}) => {
  const openBm = async ({ role }: { role?: string } = {}) => {
    let url = '/site-promos';
    const editorSDKUtils = new EditorSDKUtils(editorSDK);
    const { campaignId } = await editorSDKUtils.getWidgetProps(componentRef);
    const campaigns = await ClientApi.getCampaigns({ editorSDK });
    const campaignType = await getTypeByRef({ componentRef, editorSDK });

    const paramsObj = { [campaignType]: 'true' };

    if (role) {
      paramsObj.anchor = role;
    }

    const params = `?${new URLSearchParams(paramsObj).toString()}`;

    const isCampaigns = campaigns.length;
    const isCampaignsOfType = campaigns.filter(
      (campaign) => campaign.data.campaignType === campaignType,
    ).length;
    if (!isCampaignsOfType) {
      url += `/campaigns/create`;
    } else if (isCampaignsOfType && !campaignId) {
      url += `/campaigns`;
    } else if (isCampaigns && isCampaignsOfType && campaignId) {
      url += `/campaigns/${campaignId}`;
    }

    url += params;

    editorSDKUtils.openDashboard({ url }).then(async () => {
      const campaigns = await ClientApi.getCampaigns({ editorSDK });
      const idToSet = getInitialCampaign(componentRef, campaigns, flowAPI);
      if (!campaignId && idToSet) {
        const newProps = { [PropKeys.campaignId]: idToSet };
        await editorSDKUtils.setWidgetProps({ componentRef, newProps });
      } else {
        editorSDKUtils.refreshLivePreview(true, '');
      }
    });
  };

  const openCampaignSettings = async (campaigns: ICampaignDataItem[]) => {
    const { t } = flowAPI.translations;
    const editorSdkUtils = new EditorSDKUtils(editorSDK);
    let props = await editorSdkUtils.getWidgetProps(componentRef);
    const msid = await editorSdkUtils.getMetaSiteId();
    const instance = await editorSdkUtils.getInstance();
    const instance_id = await editorSdkUtils.getInstanceId();
    const campaignType = await getTypeByRef({ componentRef, editorSDK });

    const compIdSplit = props.compId.split('_')[0];

    const campaign = campaigns.find(
      (cmp) => cmp.data.promoSlot.id === compIdSplit,
    );
    if (!campaign || campaign?.id !== props.campaignId) {
      const newProps = { [PropKeys.campaignId]: campaign?.id || '' };
      await editorSdkUtils?.setWidgetProps({ componentRef, newProps });
      props = await editorSdkUtils.getWidgetProps(componentRef);
    }

    const connectPanel = {
      componentRef,
      title: t('promotions.widget.announcement.gfpp.mainAction2'),
      url: getPanelUrl('Announcement', 'CampaignSettings'),
      initialData: {
        props,
        msid,
        componentRef,
        initialCampaigns: campaigns,
        instance,
        instance_id,
        campaignType,
      },
      width: DIMENSIONS.panelWidth,
      height: DIMENSIONS.panelHeight,
      helpId: HELP_ID,
    };

    const panel = connectPanel;

    return editorSdkUtils.openComponentPanel(panel);
  };

  const openSettings = async () => {
    if (!options) {
      return;
    }
    const isManagePromoBM = flowAPI.experiments.enabled(
      EXPERIMENTS.MANAGE_PROMOS_BM,
    );

    // const refId = componentRef?.scope?.id;
    const panelsApi = (await new PanelsApiFactory().createPanelsApi({
      editorSDK,
      editorType: options.origin.type,
      essentials: options.essentials,
    })) as IPanelsApi;
    const campaignType = await getTypeByRef({ componentRef, editorSDK });
    const panels = PanelsByType[campaignType];
    const campaigns = await ClientApi.getCampaigns({ editorSDK });
    const campaignsOfType = campaigns?.filter(
      (campaign) => campaign.data.campaignType === campaignType,
    ) as ICampaignDataItem[];
    const isCampaignsOfType = campaignsOfType.length;
    const isAllExpired =
      isCampaignsOfType &&
      campaignsOfType.every((campaign) =>
        Utils.getIsExpired(campaign.data.campaignDateEnd),
      );
    let panelId;
    if (!isCampaignsOfType) {
      panelId = panels.settingsEmptystate;
    } else if (isAllExpired) {
      panelId = panels.settingsEmptystateExp;
    } else if (!isManagePromoBM) {
      panelId = panels.settingsChooseCampaign;
    } else {
      await openCampaignSettings(campaignsOfType);
      return;
    }

    await panelsApi.openBlocksPanel(panelId, componentRef, {
      overrides: {
        title: flowAPI.translations.t(
          'promotions.widget.announcement.gfpp.mainAction2',
        ),
      },
    });
  };

  const openElements = async ({ t }) => {
    const campaignType = await getTypeByRef({ componentRef, editorSDK });

    await openElementsPanel(editorSDK, componentRef, campaignType, t, flowAPI);
  };

  const openLayout = async () => {
    if (!options) {
      return;
    }
    const campaignType = await getTypeByRef({ componentRef, editorSDK });
    const panelsApi = (await new PanelsApiFactory().createPanelsApi({
      editorSDK,
      editorType: options.origin.type,
      essentials: options.essentials,
    })) as IPanelsApi;
    const panels = PanelsByType[campaignType];
    await panelsApi.openBlocksPanel(panels.layout, componentRef, {
      overrides: {
        title: flowAPI.translations.t('promotions.panel.layout.title'),
      },
    });
  };

  return {
    openElements,
    openSettings,
    openCampaignSettings,
    openBm,
    openLayout,
  };
};

export const getTypeByRef = async ({
  componentRef,
  editorSDK,
}): Promise<CampaignType> => {
  const editorSdkUtils = new EditorSDKUtils(editorSDK);
  const preset = await editorSdkUtils.getPreset(componentRef);
  const presetLayoutId = preset?.layout as keyof typeof presetToType;
  const widgetType = presetToType[presetLayoutId];

  if (widgetType) {
    return widgetType;
  } else {
    console.log('No preset type found', { componentRef, preset });
    return 'announcements_type';
  }
};

export const manipluateGFPP = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t: any,
) => {
  const openBmOnMainAction2 = ({
    elements,
    flowAPI,
  }: {
    elements: string[];
    flowAPI: EditorScriptFlowAPI;
  }) => {
    for (const elId of elements) {
      widgetBuilder.configureConnectedComponents(
        elId,
        (connectedComponentsBuilder) => {
          connectedComponentsBuilder.gfpp().set('mainAction2', {
            label: t('promotions.connectedElements.text.editText'),
            onClick: async (event) => {
              const { controllerRef, role } = event.detail;
              const { openBm } = createGfppActions({
                editorSDK,
                componentRef: controllerRef,
                flowAPI,
              });
              openBm({ role });
            },
          });
        },
      );
    }
  };

  const removeGfppBtn = ({ elements, btnToRemove, isMobile = false }) => {
    const viewport = isMobile ? 'mobile' : 'desktop';
    elements.forEach((element) => {
      widgetBuilder.configureConnectedComponents(
        element,
        (connectedComponentsBuilder) => {
          connectedComponentsBuilder
            .gfpp(viewport)
            .set(btnToRemove, { behavior: 'HIDE' });
        },
      );
    });
  };

  const updateLinkAndTextGfpp = ({ elements }) => {
    elements.forEach((el) => {
      widgetBuilder.configureConnectedComponents(
        el.id,
        (connectedComponentsBuilder) => {
          connectedComponentsBuilder.behavior().set({
            essential: { enabled: true },
            closed: { selectable: true },
          });

          connectedComponentsBuilder
            .gfpp()
            .set('mainAction1', {
              label: t('promotions.connectedElements.stylabeButton.mainAction'),

              onClick: (event: any) => {
                const { componentRef } = event.detail;
                const editorSdkUtils = new EditorSDKUtils(editorSDK);
                editorSdkUtils.openWidgetMenu(componentRef, el.isLabelHidden);
              },
            })

            .set('link', {
              behavior: 'HIDE',
            })
            .set('layout', {
              behavior: 'HIDE',
            })
            .set('animation', {
              behavior: 'HIDE',
            });
        },
      );
    });
  };
  return { openBmOnMainAction2, updateLinkAndTextGfpp, removeGfppBtn };
};
